import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MdElectricCar } from 'react-icons/md';
import moment from 'moment';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import Swal from 'sweetalert2';

import Input from '../../components/Input';
import Button from '../../components/Button';

import {
  Container,
  Header,
  Content,
  ContainerSearch,
  ContainerRow,
  ContainerFlex,
} from './styles';
import { colors } from '../../commonStyle';

import api from '../../services/api';

export default function Home() {
  const navigation = useNavigate();

  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState('');
  const [data, setData] = useState([]);

  async function loadTracking() {
    if (code?.length < 10) {
      return Swal.fire('Digite um código válido para fazer a busca.');
    }

    setLoading(true);

    try {
      const response = await api.get(`/tracking/${code}`);
      setData(response.data);
    } catch (err) {
      Swal.fire('Erro ao tentar buscar o codigo.');
      //alert('Erro ao tentar buscar o codigo.');
    } finally {
      setLoading(false);
    }
  }

  return (
    <Container>
      <Header>
        <h1>Rastreia Fácil Correios</h1>
      </Header>

      <Content>
        <ContainerSearch>
          <h3>Digite o código de rastreamento</h3>
          <ContainerRow style={{ marginTop: 10 }}>
            <Input
              onPress={() => setCode('')}
              value={code}
              onChange={(e) => setCode(e.target.value)}
            />
            <div style={{ width: '20%', marginLeft: 20 }}>
              <Button text="Buscar" loading={loading} onPress={loadTracking} />
            </div>
          </ContainerRow>
        </ContainerSearch>

        {loading && (
          <ContainerSearch>
            <Skeleton height={40} />
            <div style={{ marginBottom: 10 }} />
            <Skeleton height={22} />
            <Skeleton height={22} />
            <Skeleton height={22} />
            <Skeleton height={22} />
            <Skeleton height={22} />
          </ContainerSearch>
        )}

        {!loading && data.length > 0 && (
          <ContainerSearch style={{ marginTop: 20 }}>
            <h1 style={{ marginBottom: 10 }}>{data[0]?.code}</h1>
            <h3>Tipo: {data[0]?.type}</h3>
            <h3>Descrição: {data[0]?.description}</h3>
            <h3>
              Data da postagem:{' '}
              {data[0]?.postedAt
                ? moment(data[0]?.postedAt).format('DD/MM/YYYY - HH:mm:ss')
                : null}
            </h3>
            <h3>
              Ultima atualização:{' '}
              {data[0]?.updatedAt
                ? moment(data[0]?.updatedAt).format('DD/MM/YYYY - HH:mm:ss')
                : null}
            </h3>
            <h3>Entrega Realizada: {data[0]?.isDelivered ? 'SIM' : 'NÃO'}</h3>
          </ContainerSearch>
        )}

        {loading && (
          <ContainerSearch>
            <ContainerRow
              style={{ alignItems: 'center', marginBottom: 40, marginTop: 20 }}
            >
              <Skeleton height={60} width={50} />
              <ContainerFlex
                style={{
                  marginLeft: 20,
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Skeleton height={15} />
                <Skeleton height={15} />
                <Skeleton height={15} />
                <Skeleton height={15} />
              </ContainerFlex>
            </ContainerRow>
            <ContainerRow
              style={{ alignItems: 'center', marginBottom: 40, marginTop: 20 }}
            >
              <Skeleton height={60} width={50} />
              <ContainerFlex
                style={{
                  marginLeft: 20,
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Skeleton height={15} />
                <Skeleton height={15} />
                <Skeleton height={15} />
                <Skeleton height={15} />
              </ContainerFlex>
            </ContainerRow>
            <ContainerRow
              style={{ alignItems: 'center', marginBottom: 40, marginTop: 20 }}
            >
              <Skeleton height={60} width={50} />
              <ContainerFlex
                style={{
                  marginLeft: 20,
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Skeleton height={15} />
                <Skeleton height={15} />
                <Skeleton height={15} />
                <Skeleton height={15} />
              </ContainerFlex>
            </ContainerRow>
          </ContainerSearch>
        )}

        {!loading && data.length > 0 && (
          <ContainerSearch>
            {data[0]?.tracks?.map((item) => (
              <ContainerRow
                style={{
                  alignItems: 'center',
                  marginBottom: 40,
                  marginTop: 20,
                }}
              >
                <MdElectricCar size={50} color={colors.black} />
                <ContainerFlex
                  style={{
                    marginLeft: 20,
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <p style={{ color: colors.red, fontWeight: 'bold' }}>
                    {item.status}
                  </p>
                  <p>{item.observation}</p>
                  <p>{item.locale}</p>
                  <p>
                    {item.trackedAt
                      ? moment(item.trackedAt).format(
                          'DD/MM/YYYY [as] HH:mm:ss'
                        )
                      : null}
                  </p>
                </ContainerFlex>
              </ContainerRow>
            ))}
          </ContainerSearch>
        )}
      </Content>
    </Container>
  );
}
